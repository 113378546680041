import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'
import BgSimuladorConsignadoPrivado from './../../assets/images/bg-simulador-hero-consignado-privado.png'

export const Section = styled.section`
  background-color: ${grayscale[100]};

  @media (min-width: ${breakpoints.md}) {
    min-height: 529px;
  }
  @media ${device.desktopLG} {
    min-height: 636px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 715px;
  }

  .container {
    .row {
      .grid-right {

        @media ${device.desktopLG} {
          padding-right: 0;
        }

        @media ${device.desktopXL} {
          padding-right: 12px;
        }
      }
    }
  }

  .bread-crumb {
    font-family: "Sora", Helvetica, sans-serif;
    span {
      font-weight: bold !important;
      color: ${orange.extra} !important;
      opacity: 1;
    }
    a {
      font-weight: 400 !important;
      color:${grayscale[400]};
      opacity: 0.7;
    }
  }
`

export const Title = styled.h1`
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color:${grayscale[500]};
  margin-bottom: 16px;
  margin-top: 40px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color:${grayscale[500]};
  margin-bottom: 50px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 22px;
    margin-top: 24px;
  }
`

export const Simulator = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin-left: 0;
  }

  ul {
    gap: 5px !important;
    justify-content: center !important;

    li {
      margin-top: 0 !important;
    }
  }

  .result-simulator {
    font-size: 28px;
  }

  @media (min-width: ${breakpoints.md}) {
    background-repeat: no-repeat;
    background-image: url(${BgSimuladorConsignadoPrivado});
    background-position: center;
    background-size: contain;

    .result-simulator {
      font-size: 22px;
    }

    & > div {
      width: 300px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    background-repeat: no-repeat;
    background-image: url(${BgSimuladorConsignadoPrivado});
    background-position: center;
    background-size: contain;

    & > div {
      width: 336px;
    }
  }

  @media ${device.desktopXL} {
    background-repeat: no-repeat;
    background-image: url(${BgSimuladorConsignadoPrivado});
    background-position: center;
    background-size: 427px;

    .result-simulator {
      font-size: 28px;
    }
  }

  @media ${device.desktopXXXL} {
    background-repeat: no-repeat;
    background-image: url(${BgSimuladorConsignadoPrivado});
    background-position: center;
    background-size: 427px;

    & > div {
      width: 536px;
    }
  }
`
